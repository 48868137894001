import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from './firebase';

export const getInterval = async () => {
    try {
        const configRef = doc(db, 'config', 'mailsConfig');
        const configSnap = await getDoc(configRef);

        if (configSnap.exists()) {
            return configSnap.data();
        }
        return null;
    } catch (error) {
        console.error('Error getting interval:', error);
        throw error;
    }
};

export const updateInterval = async (interval) => {
    try {
        const configRef = doc(db, 'config', 'mailsConfig');
        const newInterval = { showAfterSend: interval };
        return await updateDoc(configRef, newInterval);
    } catch (error) {
        console.error('Error updating interval:', error);
        throw error;
    }
};
