import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'prismjs/themes/prism.css';
import MainPage from './components/MainPage/MainPage';
import Archive from './components/Archive/archive';

function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/archive" element={<Archive />} />
        </Routes>
      </Router>
  );
}

export default App;
