
import axios from 'axios';
const API_URL = process.env.REACT_APP_STIBEE_API_URL;
const ACCESS_TOKEN = process.env.REACT_APP_STIBEE_API_KEY;
const TYPO_DOMAINS = [
    "gamil.com",
    "gmal.com",
    "gmial.com",
    "gmaik.com",
    "gnail.com",
    "gmail.co",
    "gmail.con",
    "gmail.cim",
    "gmail.cmo",
    "gmail.om",
    "gmai.com",
    "gamil.cmo",
    "gmial.cmo",
    "gma!l.com",
    "gmai1.com",
    "nvaer.com",
    "navr.com",
    "naber.com",
    "maver.com",
    "naver.co",
    "navrr.com",
    "nvaer.co",
    "naver.con",
    "naver.cim",
    "naver.cmo",
    "naver.om",
    "nvaer.cmo",
    "navar.com",
    "navr.co",
    "n@ver.com",
    "nav3r.com",
    "nave|.com",
    "naver.c0m"];
const BLACKLIST_DOMAINS = ['aol.com'];

export const validateEmail = (email, isRetry = false) => {
    let msg = "";
    let validated = true;

    if (email.length === 0) {
        msg = "이메일 주소를 입력하세요.";
        validated = false;
    }

    if (!validateEmailInDetail(email)) {
        msg = "잘못된 이메일 주소입니다.";
        validated = false;
    }
    const domain = email.split('@')[1];

    // 재시도가 아닐 때만 타이핑 체크
    if (!isRetry && TYPO_DOMAINS.includes(domain)) {
        msg = "오타가 아닌지 확인해보시겠어요? 종종 실수로 입력하는 메일 주소에요.";
        validated = false;
        return { message: msg, validated, isTypo: true };
    }

    if (BLACKLIST_DOMAINS.includes(domain)) {
        msg = "지금은 입력하신 메일 서비스의 구독을 받고 있지 않아요.";
        validated = false;
        return { message: msg, validated, isBlacklisted: true };
    }
    return { message: msg, validated };
};

const validateEmailInDetail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const requestNewSubscriber = async (
    email,
    privacyTermAgree,
    marketingTermAgree
) => {
    const subscriber = {
        email,
        privacyTermAgree,
        marketingTermAgree
    };

    const body = {
        eventOccuredBy: "SUBSCRIBER",
        confirmEmailYN: "Y",
        groupIds: [],
        subscribers: [subscriber]
    };

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'AccessToken': ACCESS_TOKEN
        }
    };

    try {
        const response = await axios.post(API_URL, body, config);
        return response.data;
    } catch (error) {
        throw error;
    }
};
