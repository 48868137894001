import React from 'react';
import './Makers.css'
const Makers = () => {
    const editors = [
        {
            name: "리니어",
            icon: "https://firebasestorage.googleapis.com/v0/b/videojockey-883a1.appspot.com/o/054-wizard%403x.png?alt=media&token=cfcbbda4-0d6f-4bfb-b068-818e9f28d599",
            introduction: [
                "워너비 미니멀리스트",
                "AI 에게 말을 가르치는 일을 하고 있음",
                "백수 때 심심해서 넷플릭스만 보다가 비쟈를 시작하게 됨"
            ]
        },
        {
            name: "욜로",
            icon: "https://firebasestorage.googleapis.com/v0/b/videojockey-883a1.appspot.com/o/035-leprechaun%403x.png?alt=media&token=e33d3909-3698-4c7a-a495-fa0edd6567e3",
            introduction: [
                "잡일 벌이기 전문가",
                "글쓰기와는 정 반대의 커리어를 걷는 중",
                "숨 쉬듯이 영화만 보는 시간을 재밌게 보내고 싶어 시작함"
            ]
        },
        {
            name: "서퍼",
            icon: "https://firebasestorage.googleapis.com/v0/b/videojockey-883a1.appspot.com/o/016-elf%403x.png?alt=media&token=2e2b6fb0-6f5d-4ce7-82ba-cdd1ae860b46",
            introduction: [
                "한량 연습생",
                "돈 많은 백수의 꿈을 품고 성실히 출근 중",
                "개발 공부 하다가 뭔가 재밌어 보여서 비쟈를 같이 하게 됨"
            ]
        }
    ];

    return (
        <div className="makers-container">
            <div className="makers-content">
                <div className="makers-header">
                    <div className="makers-title">
                        글 쓰는 사람들
                    </div>
                    <div className="makers-subtitle">
                        Editors
                    </div>
                </div>
                <div className="makers-body">
                    {editors.map((editor, index) => (
                        <div className="makers-person" key={index}>
                            <img className="makers-icon" src={editor.icon} alt={`${editor.name} icon`} />
                            <div className="makers-name makers-bold">{editor.name}</div>
                            <div className="makers-introduction makers-body-text">
                                {editor.introduction.map((line, i) => (
                                    <React.Fragment key={i}>
                                        {line}
                                        {i < editor.introduction.length - 1 && <br />}
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Makers;
