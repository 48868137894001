import {
    collection,
    query,
    where,
    orderBy,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    getDocs
} from 'firebase/firestore';
import { db } from './firebase';

const mailsCollection = collection(db, 'mails');

export const getMails = async (day) => {
    try {
        const mailsQuery = query(
            mailsCollection,
            where('published', '<', day),
            orderBy('published', 'desc')
        );

        const snapshot = await getDocs(mailsQuery);
        return snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
    } catch (error) {
        console.error('Error fetching mails:', error);
        throw error;
    }
};

export const createMail = async (mail) => {
    try {
        return await addDoc(mailsCollection, mail);
    } catch (error) {
        console.error('Error creating mail:', error);
        throw error;
    }
};

export const updateMail = async (mail) => {
    if (!mail.id) {
        throw new Error('Mail ID is required for update');
    }

    try {
        const mailDoc = doc(db, 'mails', mail.id);
        const { id, ...mailData } = mail;
        return await updateDoc(mailDoc, mailData);
    } catch (error) {
        console.error('Error updating mail:', error);
        throw error;
    }
};

export const deleteMail = async (mailId) => {
    try {
        const mailDoc = doc(db, 'mails', mailId);
        return await deleteDoc(mailDoc);
    } catch (error) {
        console.error('Error deleting mail:', error);
        throw error;
    }
};
