import React from "react";
import MainPart from "./MainPart";
import Makers from "./Makers";
import ReadLetter from "./ReadLetter";
import Footer from "./Footer";
import "./MainPage.css";

const MainPage = () => {
    return (
        <div className="mainpage-container">
            <div className="mainpage-section">
                <MainPart />
            </div>
            <div className="mainpage-section">
                <ReadLetter />
            </div>
            <div className="mainpage-section">
                <Makers />
            </div>
            <div className="mainpage-footer">
                <Footer />
            </div>
        </div>
    );
};

export default MainPage;
