// services/subscribeLogService.ts
import { collection, addDoc } from 'firebase/firestore';
import { db } from './firebase';

const logsCollection = collection(db, 'logs');

export const createLog = async (log) => {
    try {
        return await addDoc(logsCollection, log);
    } catch (error) {
        console.error('Error creating subscription log:', error);
        throw error;
    }
};
