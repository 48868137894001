// DialogTerm.js
import React from 'react';
import './DialogTerm.css';

const DialogTerm = ({ show, onClose, showPrivacy }) => {
    if (!show) return null;

    const privacyTermTitle = "개인정보 수집";
    const privacyTermContent = "뉴스레터 발송을 위한 최소한의 개인정보를 수집하고 이용합니다. \n 수집된 정보는 발송 외 다른 목적으로 이용되지 않으며, 서비스가 종료되거나 구독을 해지할 경우 즉시 파기됩니다.";
    const marketingTermTitle = "광고성 정보 수신";
    const marketingTermContent = "제휴 콘텐츠, 프로모션, 이벤트 정보 등의 광고성 정보를 수신합니다.";

    const handleModalClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div className="dialog-term-overlay" onClick={onClose}>
            <div className="dialog-term-modal" onClick={handleModalClick}>
                <div className="dialog-term-header">
                    <h4 className="dialog-term-title">
                        {showPrivacy ? privacyTermTitle : marketingTermTitle}
                    </h4>
                    <button
                        type="button"
                        className="dialog-term-close"
                        onClick={onClose}
                    >
                        &times;
                    </button>
                </div>
                <div className="dialog-term-body">
                    {showPrivacy ? privacyTermContent : marketingTermContent}
                </div>
                <div className="dialog-term-footer">
                    <button
                        type="button"
                        className="dialog-term-button"
                        onClick={onClose}
                    >
                        닫기
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DialogTerm;
