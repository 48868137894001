import React from "react";
import { Link } from "react-router-dom";
import "./ReadLetter.css";

const ReadLetter = () => {
    return (
        <div className="read-letter">
            <Link to="/archive">
                <div className="readletter-container">
                    <div className="readletter-content">
                        <h2 className="readletter-title">지난 레터 읽기</h2>
                        <p className="readletter-subtitle">Newsletter Archive</p>
                    </div>
                </div>
            </Link>
        </div>

    );
};

export default ReadLetter;
