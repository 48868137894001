// MainPart.js
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import DialogTerm from './DialogTerm';
import { validateEmail, requestNewSubscriber } from '../../services/subscribeService';
import { createLog } from '../../services/subscribeLogService';
import { trackEvent } from '../../services/analyticsService';
import './MainPart.css';

const MainPart = () => {
    const [errorMsg, setErrorMsg] = useState('');
    const [errorStatus, setErrorStatus] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(true);
    const [emailAddress, setEmailAddress] = useState('');
    const [isTypoRetry, setIsTypoRetry] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const messages = {
        duplicated: "이미 구독 중인 이메일 주소입니다.",
        unknown: "잠시 후 다시 이용해주세요.",
        wrong: "이메일 주소를 다시 확인해주세요."
    };

    // 다이얼로그 핸들러 함수들
    const handleOpenPrivacy = (e) => {
        e.preventDefault(); // 기본 이벤트 방지
        setShowPrivacy(true);
        setShowDialog(true);
    };

    const handleOpenMarketing = (e) => {
        e.preventDefault(); // 기본 이벤트 방지
        setShowPrivacy(false);
        setShowDialog(true);
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const onSubmit = async (data) => {
        try {
            const validationResult = validateEmail(emailAddress, isTypoRetry);

            if (!validationResult.validated) {
                setErrorStatus(true);
                setErrorMsg(validationResult.message);

                if (validationResult.isTypo) {
                    setIsTypoRetry(true);
                } else if (validationResult.isBlacklisted) {
                    setIsTypoRetry(false);
                }

                await createLog({
                    email: emailAddress,
                    status: 'failWrongEmailWebpage',
                    datetime: new Date()
                });

                trackEvent('subscribe', 'subscribe', 'click', 'failWrongEmailWebpage', 1);
                return;
            }

            const response = await requestNewSubscriber(
                emailAddress,
                data.acceptPrivacyTerms,
                data.acceptMarketingTerms,
                isTypoRetry
            );

            let status = 'success';
            console.log(response);
            if (response.Value.failExistEmail?.length > 0) {
                setErrorStatus(true);
                setErrorMsg(messages.duplicated);
                status = 'duplicated';
            } else if (response.Value.failUnknown?.length > 0) {
                setErrorStatus(true);
                setErrorMsg(messages.unknown);
                status = 'failUnknownServer';
            } else if (response.Value.failWrongEmail?.length > 0) {
                setErrorStatus(true);
                setErrorMsg(messages.wrong);
                status = 'failWrongEmailServer';
            } else if (response.Value.success?.length > 0) {
                setErrorStatus(false);
                setSubmitted(true);
                setIsTypoRetry(false);
            }

            await createLog({
                email: emailAddress,
                status,
                datetime: new Date()
            });

            trackEvent('subscribe', 'subscribe', 'click', status, 1);

        } catch (error) {
            console.error('Subscription error:', error);
            setErrorStatus(true);
            setErrorMsg(messages.unknown);

            await createLog({
                email: emailAddress,
                status: 'error',
                datetime: new Date()
            });

            trackEvent('subscribe', 'subscribe', 'click', 'error', 1);
        }
    };

    return (
        <div className="mainpart-container">
            <div className="mainpart-polygon1">
                <img src="https://storage.googleapis.com/videojockey-883a1.appspot.com/Polygon.png" alt="Polygon 1" />
            </div>
            <div className="mainpart-big-circle1" />
            <div className="mainpart-big-circle2" />
            <div className="mainpart-small-circle1" />
            <div className="mainpart-polygon2">
                <img src="https://storage.googleapis.com/videojockey-883a1.appspot.com/Polygon2.png" alt="Polygon 2" />
            </div>
            <div className="mainpart-small-circle2" />
            <div className="mainpart-small-circle3" />

            <div className="mainpart-content-wrapper">
                <header className="mainpart-header">
                    <img
                        className="mainpart-tape"
                        src="https://storage.googleapis.com/videojockey-883a1.appspot.com/invalid-name%403x.png"
                        alt="Tape"
                    />
                    <img
                        className="mainpart-title"
                        src="https://storage.googleapis.com/videojockey-883a1.appspot.com/title%403x.png"
                        alt="Title"
                    />
                </header>

                <div className="mainpart-description">
                    <p className="mainpart-description-text normal">
                        지친 당신의 편안한 주말을 함께할 <br />
                        비디오 쟈-키 입니다. <br />
                        매주 영화와 드라마를 추천해드려요! <br />
                    </p>

                    <p className="mainpart-description-text bold">
                        금요일 저녁 7시
                    </p>

                    <p className="mainpart-description-text">
                        비디오쟈-키의 한땀 한땀 콘텐츠 큐레이션이 <br />
                        당신의 메일함으로 찾아 갑니다.
                    </p>
                </div>

                <div className="mainpart-subscription">
                    {(!submitted || (errorStatus)) ? (
                        <form onSubmit={handleSubmit(onSubmit)} className="mainpart-form">
                            <div className="mainpart-form-group">
                                <label className="mainpart-form-label">메일 주소</label>
                                <input
                                    type="email"
                                    className="mainpart-form-input"
                                    value={emailAddress}
                                    onChange={(e) => {
                                        setEmailAddress(e.target.value);
                                        setIsTypoRetry(false);  // 텍스트 수정 시 retry 상태 초기화
                                    }}
                                />
                                {errorStatus && (
                                    <div className="mainpart-form-error">{errorMsg}</div>
                                )}
                            </div>

                            <div className="mainpart-form-checkbox">
                                <input
                                    type="checkbox"
                                    id="acceptPrivacyTerms"
                                    {...register('acceptPrivacyTerms', { required: true })}
                                    className="mainpart-checkbox"
                                />
                                <label htmlFor="acceptPrivacyTerms" className="mainpart-checkbox-label">
                                    <strong
                                        onClick={handleOpenPrivacy}
                                        className="mainpart-terms-link"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        개인정보 수집 및 이용
                                    </strong> 동의
                                </label>
                                {errors.acceptPrivacyTerms && (
                                    <div className="mainpart-form-error">
                                        개인 정보 약관 동의가 필요해요.
                                    </div>
                                )}
                            </div>

                            <div className="mainpart-form-checkbox">
                                <input
                                    type="checkbox"
                                    id="acceptMarketingTerms"
                                    {...register('acceptMarketingTerms', { required: true })}
                                    className="mainpart-checkbox"
                                />
                                <label htmlFor="acceptMarketingTerms" className="mainpart-checkbox-label">
                                    <strong
                                        onClick={handleOpenMarketing}
                                        className="mainpart-terms-link"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        광고성 정보 수신
                                    </strong> 동의
                                </label>
                            </div>

                            <button type="submit" className="mainpart-submit-button">
                                {isTypoRetry ? '이 주소 그대로 구독하기' : '구독하기'}
                            </button>
                        </form>
                    ) : (
                        <div className="mainpart-success">
                            <p>확인 메일 발송 완료</p>
                            <p>메일함에서 <strong>구독 확인 메일</strong>을 확인하면 구독이 완료됩니다!</p>
                        </div>
                    )}
                </div>

                {/* DialogTerm 컴포넌트 추가 */}
                <DialogTerm
                    show={showDialog}
                    onClose={handleCloseDialog}
                    showPrivacy={showPrivacy}
                />

            </div>
        </div>
    );
};

export default MainPart;
