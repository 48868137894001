import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import * as mailService from '../../services/mailService';
import * as intervalService from '../../services/intervalService';
import styles from './archive.module.css';

const ArchiveComponent = () => {
    const [mails, setMails] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [interval, setInterval] = useState(3);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const maxSize = 6;

    useEffect(() => {
        const adjustItemsPerPage = () => {
            const width = window.innerWidth;
            const height = window.innerHeight;

            if (width > 1230) {
                setItemsPerPage(10);
            } else if (height > 800) {
                setItemsPerPage(7);
            } else if (height > 730) {
                setItemsPerPage(6);
            } else {
                setItemsPerPage(5);
            }
        };

        const loadMails = async () => {
            try {
                setIsLoading(true);
                const intervalData = await intervalService.getInterval();
                const weeksInterval = intervalData?.showAfterSend || 3;
                setInterval(weeksInterval);

                const day = new Date();
                day.setDate(day.getDate() - weeksInterval * 7);

                const mailsData = await mailService.getMails(day);
                setMails(mailsData);
            } catch (error) {
                console.error('Error loading mails:', error);
            } finally {
                setIsLoading(false);
            }
        };

        window.addEventListener('resize', adjustItemsPerPage);
        adjustItemsPerPage();
        loadMails();

        return () => window.removeEventListener('resize', adjustItemsPerPage);
    }, []);

    const totalPages = Math.ceil(mails.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentMails = mails.slice(startIndex, endIndex);

    const getPageNumbers = () => {
        const pages = [];
        const halfMaxSize = Math.floor(maxSize / 2);

        let startPage = Math.max(currentPage - halfMaxSize, 1);
        let endPage = Math.min(startPage + maxSize - 1, totalPages);

        if (endPage - startPage + 1 < maxSize) {
            startPage = Math.max(endPage - maxSize + 1, 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }
        return pages;
    };

    if (isLoading) {
        return (
            <div className={styles.loadingContainer}>
                <img
                    src="https://storage.googleapis.com/videojockey-883a1.appspot.com/invalid-name%403x.png"
                    alt="Tape"
                    className={styles.tapeImage}
                />
                <div className={styles.loadingText}>이메일 가져오는 중</div>
                <div className={styles.loadingSpinner}></div>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <a href="/" className={styles.goBack}>
                    <img
                        src="https://storage.googleapis.com/videojockey-883a1.appspot.com/backButton.png"
                        alt="Go back"
                    />
                </a>
                <div className={styles.titleSection}>
                    <div className={styles.title}>지난 레터 읽기</div>
                    <div className={styles.subtitle}>Newsletter archive</div>
                    <div className={styles.opMessage}>
                        <div>최신 비디오는 발송 3주 후에 들어와요.</div>
                    </div>
                </div>
            </div>

            <div className={styles.content}>
                <div className={styles.boxContainer}>
                    {currentMails.map((mail) => (
                        <a key={mail.id} href={mail.url}>
                            <div className={styles.box}>
                                <div className={styles.pc}>
                                    {parseFloat(mail.id) < 1000 && (
                                        <div className={styles.textBox}>
                                            Vol {mail.id}. {mail.mailTitle} {mail.filmTitle && `<${mail.filmTitle}>`}
                                        </div>
                                    )}
                                    {parseFloat(mail.id) >= 1000 && (
                                        <div className={styles.mailTitle}>
                                            {mail.mailTitle}
                                        </div>
                                    )}
                                </div>
                                <div className={styles.mobile}>
                                    <div className={styles.mailTitle}>
                                        <div className={styles.textBox}>
                                            <p>Vol {mail.id}. {mail.mailTitle.length > 22 ? `${mail.mailTitle.slice(0, 22)}...` : mail.mailTitle}</p>
                                            {mail.filmTitle && <p>{`<${mail.filmTitle}>`}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>

                <div className={styles.navigation}>
                    <button
                        className={styles.navButton}
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                    >
                        {'<'}
                    </button>

                    {getPageNumbers().map((pageNum) => (
                        <div
                            key={pageNum}
                            className={`${styles.pageNumber} ${currentPage === pageNum ? styles.current : ''}`}
                            onClick={() => setCurrentPage(pageNum)}
                        >
                            {currentPage === pageNum && <div className={styles.triangle} />}
                            <span>{pageNum}</span>
                        </div>
                    ))}

                    <button
                        className={styles.navButton}
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                        disabled={currentPage === totalPages}
                    >
                        {'>'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ArchiveComponent;
